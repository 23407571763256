export default function useHeadMeta(metadata) {
  useHead({
    title: `${metadata.title || null}`,
    meta: [
      { name: "description", content: metadata.description || null },
      { name: "keywords", content: metadata.keywords || null },
      {
        property: "og:url",
        content: `https://www.opero.cz/${metadata.url || null}`,
      },
      {
        property: "og:title",
        content: `${metadata.title || null}`,
      },
      { property: "og:description", content: metadata.description || null },
      {
        property: "og:image",
        content: `${metadata.image || null}`,
      },
      {
        property: "twitter:url",
        content: `https://www.opero.cz/${metadata.url || null}`,
      },
      {
        property: "twitter:title",
        content: `${metadata.title || null}`,
      },
      {
        property: "twitter:description",
        content: metadata.description || null,
      },
      {
        property: "twitter:image",
        content: metadata.image || null,
      },
    ],
  });
}
